import React, { useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';
import {
    Button,
    FormControl,
    Typography,
} from '../../../../../components/libs';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../hooks';
import { ManualReportsForms } from '../../../../../stores/manualReports';
import moment from 'moment/moment';
// import { DatePicker } from '@material-ui/pickers';
import { DayLoadResult } from '../../../../../services/manualReports/requestResponses';
import {
    EnhancedTableHead,
    HeadCell,
} from '../../../../../components/TableHelpers';
import Loader from '../../../../../components/Loader';
import { DateRange } from 'rsuite/DateRangePicker';
import { DatePicker } from '../../../../../utils/DatePicker';
import { HStack } from 'rsuite';

const tableHeaders: Array<HeadCell> = [
    { id: 'dayDt', alignment: 'left', label: 'День' },
    { id: 'isOk', alignment: 'left', label: 'Статус' },
    { id: 'errors', alignment: 'left', label: 'Ошибки при загрузке' },
];

const LoadManualReportForm: React.FC = () => {
    const { manualReportsStore, placesStore, generalStore } = useStores();

    const [dateRange, setDateRange] = useState<DateRange>();

    const [loadResult, setLoadResult] = useState<DayLoadResult[] | null>(null);

    useEffect(() => {
        setLoadResult(null);
        setDateRange([
            moment().subtract(1, 'days').toDate(),
            moment().toDate(),
        ]);
    }, [manualReportsStore.formsState.LoadForm]);
    const loadManualReports = async () => {
        if (dateRange?.[0] && dateRange?.[1]) {
            manualReportsStore.setLoading(true);
            const result = await manualReportsStore.loadManualReports(
                placesStore.currentPlace.id,
                {
                    sinceDt: moment(dateRange[0]).hour(12).toDate(),
                    tillDt: moment(dateRange[1]).hour(12).toDate(),
                },
            );

            if (result == null) {
                generalStore.showSnackbar('Не удалось скачать даныне');
            } else {
                setLoadResult(result);
            }
            manualReportsStore.setLoading(false);
        }
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={manualReportsStore.formsState.LoadForm}
        >
            <DialogTitle>Загрузка отчета за период</DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <HStack>
                        <label style={{ width: 150 }}>Период: </label>
                        <DatePicker
                            size={'md'}
                            value={dateRange}
                            onChange={(value) => {
                                setDateRange(value);
                            }}
                        />
                    </HStack>
                </Box>
                {loadResult && !manualReportsStore.isLoading && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant={'h4'}>
                            Результат загрузки
                        </Typography>
                        <Table
                            aria-labelledby='tableTitle'
                            size={'medium'}
                            aria-label='enhanced table'
                            style={{ marginTop: 10 }}
                        >
                            <EnhancedTableHead cells={tableHeaders} />
                            <TableBody>
                                {loadResult.map((dayResult, index) => (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={`${index}`}
                                    >
                                        <TableCell align='left'>
                                            <Typography variant={'subtitle1'}>
                                                {dayResult.dayDt}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='left'>
                                            <Typography variant={'subtitle1'}>
                                                {dayResult.isOk ? '✅' : '🚫'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='left'>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {dayResult.errors.map(
                                                    (error) => (
                                                        <Typography
                                                            variant={
                                                                'subtitle1'
                                                            }
                                                        >
                                                            {error}
                                                        </Typography>
                                                    ),
                                                )}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color='default'
                    variant='contained'
                    onClick={() =>
                        manualReportsStore.closeForm(
                            ManualReportsForms.LoadForm,
                        )
                    }
                >
                    Отмена
                </Button>
                <Button
                    color='primary'
                    variant={'contained'}
                    disabled={
                        manualReportsStore.isLoading ||
                        !dateRange?.[0] ||
                        !dateRange?.[1]
                    }
                    onClick={() => loadManualReports()}
                >
                    Загрузить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(LoadManualReportForm);
