import React, { useEffect, useState } from 'react';
import { useStores } from '../../../../../hooks';
import { Button, Paper } from '../../../../../components/libs';
import { Box } from '@material-ui/core';
import { DatePicker } from '../../../../../utils/DatePicker';
import { DateRange } from 'rsuite/DateRangePicker';
import moment from 'moment/moment';
import { observer } from 'mobx-react';
import { AnalyticsForms } from '../../../../../stores/analytics';

const FiltersBlock: React.FC = () => {
    const { analyticsStore, placesStore } = useStores();

    const [dateRange, setDateRange] = useState<DateRange>([
        moment().subtract(7, 'days').toDate(),
        moment().toDate(),
    ]);

    useEffect(() => {
        if (dateRange?.[0] && dateRange?.[1] && placesStore.currentPlace) {
            analyticsStore.loadMetrics({
                placeId: placesStore.currentPlace.id,
                sinceDt: moment(dateRange[0]).hour(12).toDate(),
                tillDt: moment(dateRange[1]).hour(12).toDate(),
            });
        }
    }, [dateRange, placesStore.currentPlace?.id]);

    const loadMetrics = () => {
        analyticsStore.loadMetrics({
            placeId: placesStore.currentPlace.id,
            sinceDt: moment(dateRange[0]).hour(12).toDate(),
            tillDt: moment(dateRange[1]).hour(12).toDate(),
        });
    };

    return (
        <Paper p={2}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gridGap: 10,
                    }}
                >
                    <DatePicker
                        onChange={(value) => {
                            setDateRange(value);
                        }}
                        value={dateRange}
                        size={'lg'}
                    />
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        disabled={!dateRange?.[0] || !dateRange?.[1]}
                        onClick={() => {
                            loadMetrics();
                        }}
                    >
                        Показать
                    </Button>
                </Box>
                <Box
                    sx={{
                        alignContent: 'center',
                    }}
                >
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                            analyticsStore.openForm(
                                AnalyticsForms.UpdateMetrics,
                            );
                        }}
                    >
                        Обновить метрики
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default observer(FiltersBlock);
