import React, { useEffect, useState } from 'react';
import Table from 'rsuite/esm/Table';
import { useStores } from '../../../../../hooks';
import { Paper } from '../../../../../components/libs';
import { Metrics } from '../../../../../services/analytics/models';
import { observer } from 'mobx-react';
import { CellProps } from 'rsuite/cjs';

const MetricsTable: React.FC = () => {
    const { analyticsStore } = useStores();

    const [tableData, setTableData] = useState([]);

    const getPrecision1 = (metrics: Metrics) => {
        const divider = metrics.notDetected + metrics.detected;

        if (!divider) {
            return (0).toFixed(2);
        }

        return (metrics.detected / divider) * 100;
    };

    const getPrecision2 = (metrics: Metrics) => {
        const divider =
            metrics.notDetected + metrics.detected + metrics.wronglyDetected;

        if (!divider) {
            return (0).toFixed(2);
        }
        return (metrics.detected / divider) * 100;
    };

    useEffect(() => {
        const newData = analyticsStore.metricsData.map((dayData, i) => {
            const vehiclesMetrics: {
                vehicleClass: string;
                metrics: Metrics;
            }[] = [];

            for (const vehicleClass in dayData.metricsByClass) {
                const vehicleMetrics = dayData.metricsByClass[vehicleClass];

                vehiclesMetrics.push({
                    vehicleClass: vehicleClass,
                    metrics: vehicleMetrics,
                });
            }

            vehiclesMetrics.sort((a, b) => {
                const aTotal = a.metrics.detected + a.metrics.notDetected;
                const bTotal = b.metrics.detected + b.metrics.notDetected;

                return bTotal - aTotal;
            });

            return {
                id: `${i}`,
                label: dayData.dayStr,
                detected: dayData.totalMetrics.detected.toFixed(2),
                notDetected: dayData.totalMetrics.notDetected.toFixed(2),
                wronglyDetected:
                    dayData.totalMetrics.wronglyDetected.toFixed(2),
                precision1: getPrecision1(dayData.totalMetrics),
                precision2: getPrecision2(dayData.totalMetrics),
                children: vehiclesMetrics.map((vehicleMetrics, j) => {
                    return {
                        id: `${i}-${j}`,
                        label: vehicleMetrics.vehicleClass,
                        detected: vehicleMetrics.metrics.detected.toFixed(2),
                        notDetected:
                            vehicleMetrics.metrics.notDetected.toFixed(2),
                        wronglyDetected:
                            vehicleMetrics.metrics.wronglyDetected.toFixed(2),
                        precision1: getPrecision1(vehicleMetrics.metrics),
                        precision2: getPrecision2(vehicleMetrics.metrics),
                    };
                }),
            };
        });

        setTableData(newData);
    }, [analyticsStore.metricsData]);

    const CustomCell = ({ rowData, dataKey, ...props }: CellProps<any>) => {
        let valueNumber = 0.0;
        switch (dataKey) {
            case 'precision1': {
                valueNumber = rowData.precision1;
                break;
            }
            case 'precision2': {
                valueNumber = rowData.precision2;
                break;
            }
        }

        let color: string | null = null;

        if (!valueNumber || valueNumber < 1) {
            color = null;
        } else if (valueNumber < 60) {
            color = '#faa9a7';
        } else if (valueNumber >= 60 && valueNumber < 85) {
            color = '#ffe2b3';
        } else {
            color = '#aee0ad';
        }

        let value = '';

        if (rowData[dataKey] && !isNaN(rowData[dataKey])) {
            value = parseFloat(rowData[dataKey]).toFixed(2);
        } else {
            value = rowData[dataKey];
        }

        return (
            <Table.Cell
                rowData={rowData}
                // @ts-ignore
                dataKey={dataKey}
                style={{ background: color }}
                {...props}
            >
                {value}%
            </Table.Cell>
        );
    };

    return (
        <Paper p={2}>
            <Table
                loading={analyticsStore.isLoading}
                data={tableData}
                defaultExpandAllRows
                bordered
                cellBordered
                rowKey='id'
                // height={400}
                autoHeight
                isTree
            >
                <Table.Column flexGrow={2}>
                    <Table.HeaderCell>День/Вид техники</Table.HeaderCell>
                    <Table.Cell dataKey='label' />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Опеределено (мин)</Table.HeaderCell>
                    <Table.Cell dataKey={'detected'} />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Неопределено (мин)</Table.HeaderCell>
                    <Table.Cell dataKey='notDetected' />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Перепутано (мин)</Table.HeaderCell>
                    <Table.Cell dataKey='wronglyDetected' />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Точность 1</Table.HeaderCell>
                    <CustomCell dataKey={'precision1'} />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Точность 2</Table.HeaderCell>
                    <CustomCell dataKey={'precision2'} />
                </Table.Column>
            </Table>
        </Paper>
    );
};

export default observer(MetricsTable);
