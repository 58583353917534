import {
    DownloadReportRequest,
    GetMetricsRequest,
    GetMetricsResponse,
    GetVehiclesMonitoringAnalyticsRequest,
    GetVehiclesMonitoringAnalyticsResponse,
    UpdateMetricsRequest,
    UpdateMetricsResponse,
} from '../services/analytics/requestResponses';
import {
    DayMetrics,
    VehicleMonitoringItem,
} from '../services/analytics/models';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { downloadRequest, request } from '../apiCommandsExecutor';
import { ManualReportsForms } from './manualReports';

export enum AnalyticsForms {
    UpdateMetrics = 'UpdateMetrics',
}

class AnalyticsStore {
    formsState: { [key in AnalyticsForms]: boolean } = {
        UpdateMetrics: false,
    };
    isLoading: boolean = false;
    isMetricsLoading: boolean = false;
    vehiclesMonitoringData: VehicleMonitoringItem[] = [];
    metricsData: DayMetrics[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setLoading(v: boolean) {
        this.isLoading = v;
    }

    @action
    setMetricsLoading(v: boolean) {
        this.isMetricsLoading = v;
    }

    @action
    openForm(type: keyof typeof AnalyticsForms) {
        this.formsState[type] = true;
    }

    @action
    closeForm(type: keyof typeof AnalyticsForms) {
        this.formsState[type] = false;
    }

    async loadMetrics(params: GetMetricsRequest) {
        this.setMetricsLoading(true);

        const result = await request<GetMetricsResponse>('/analytics/metrics', {
            params: params,
        });

        if (result) {
            runInAction(() => {
                console.log('run in action');
                this.metricsData = [...result.metrics];
            });
        }

        this.setMetricsLoading(false);
    }

    async updateMetrics(params: UpdateMetricsRequest) {
        this.setMetricsLoading(true);

        const result = await request<UpdateMetricsResponse>(
            '/analytics/metrics',
            {
                method: 'POST',
                data: {
                    ...params,
                },
            },
        );

        this.setMetricsLoading(false);

        if (result) {
            return result.updateResults;
        } else {
            return null;
        }
    }

    async loadAnalytics(params: GetVehiclesMonitoringAnalyticsRequest) {
        this.setLoading(true);

        const result = await request<GetVehiclesMonitoringAnalyticsResponse>(
            '/analytics/monitoring',
            {
                params: params,
            },
        );

        if (result) {
            runInAction(() => {
                console.log('run in aciton');
                this.vehiclesMonitoringData = result.monitoringData;
            });
        }
        this.setLoading(false);
    }

    async downloadReport(params: DownloadReportRequest) {
        const result = await downloadRequest('/analytics/download', {
            params: params,
        });

        const contDis = result.headers['content-disposition'];
        const filename = contDis.split('=')[1];

        const href = URL.createObjectURL(result.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }
}

export default AnalyticsStore;
