import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';
import { useStores } from '../../../../../hooks';
import { Button, Typography } from '../../../../../components/libs';
import { AnalyticsForms } from '../../../../../stores/analytics';
import { DatePicker } from '../../../../../utils/DatePicker';
import { DateRange } from 'rsuite/DateRangePicker';
import moment from 'moment';
import { DayUpdateMetricsResult } from '../../../../../services/analytics/requestResponses';
import {
    EnhancedTableHead,
    HeadCell,
} from '../../../../../components/TableHelpers';
import { CheckPicker, HStack } from 'rsuite';

const tableHeaders: Array<HeadCell> = [
    { id: 'dayDt', alignment: 'left', label: 'День' },
    { id: 'isUpdated', alignment: 'left', label: 'Статус' },
    { id: 'errors', alignment: 'left', label: 'Ошибки при загрузке' },
];
const UpdateMetricsForm: React.FC = () => {
    const { analyticsStore, placesStore, generalStore, camerasStore } =
        useStores();

    const [dateRange, setDateRange] = useState<DateRange>();

    const [results, setResults] = useState<DayUpdateMetricsResult[]>([]);
    const [selectedCameraIds, setSelectedCameraIds] = useState<string[]>([]);

    useEffect(() => {
        camerasStore.loadCameras();
    }, [placesStore.currentPlace?.id]);

    useEffect(() => {
        setResults([]);
        setDateRange([
            moment().subtract(7, 'days').toDate(),
            moment().toDate(),
        ]);
    }, [analyticsStore.formsState.UpdateMetrics]);

    useEffect(() => {
        setSelectedCameraIds(
            camerasStore.cameras
                .filter((item) => {
                    return ['33', '40', '37'].includes(item.externalId);
                })
                .map((item) => item.id),
        );
    }, [camerasStore.cameras]);

    const updateMetrics = async () => {
        setResults([]);
        const updateResults = await analyticsStore.updateMetrics({
            placeId: placesStore.currentPlace.id,
            sinceDt: moment(dateRange[0]).hour(12).toDate(),
            tillDt: moment(dateRange[1]).hour(12).toDate(),
            excludedCameraIds: selectedCameraIds,
        });

        if (updateResults) {
            setResults(updateResults);
        } else {
            generalStore.showSnackbar('Не удалось обновить метрики');
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={analyticsStore.formsState.UpdateMetrics}
        >
            <DialogTitle>Обновление метрик</DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gridGap: 20,
                    }}
                >
                    <HStack>
                        <label style={{ width: 150 }}>Период: </label>
                        <DatePicker
                            size={'md'}
                            value={dateRange}
                            onChange={(value) => {
                                setDateRange(value);
                            }}
                        />
                    </HStack>
                    <HStack>
                        <label style={{ width: 150 }}>Исключить камеры: </label>
                        <CheckPicker
                            placeholder={'Исключить камеры'}
                            searchable={false}
                            value={selectedCameraIds}
                            style={{ width: 300 }}
                            data={camerasStore.cameras.map((item) => {
                                return {
                                    label: `${item.title} (${item.externalId})`,
                                    value: item.id,
                                };
                            })}
                            onChange={(values) => setSelectedCameraIds(values)}
                        />
                    </HStack>
                </Box>

                {!analyticsStore.isMetricsLoading && results.length > 0 && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant={'h4'}>
                            Результат загрузки
                        </Typography>
                        <Table
                            aria-labelledby='tableTitle'
                            size={'medium'}
                            aria-label='enhanced table'
                            style={{ marginTop: 10 }}
                        >
                            <EnhancedTableHead cells={tableHeaders} />
                            <TableBody>
                                {results.map((dayResult, index) => (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={`${index}`}
                                    >
                                        <TableCell align='left'>
                                            <Typography variant={'subtitle1'}>
                                                {dayResult.dtStr}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='left'>
                                            <Typography variant={'subtitle1'}>
                                                {dayResult.isUpdated
                                                    ? '✅'
                                                    : '🚫'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='left'>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {dayResult.errors.map(
                                                    (error) => (
                                                        <Typography
                                                            variant={
                                                                'subtitle1'
                                                            }
                                                        >
                                                            {error}
                                                        </Typography>
                                                    ),
                                                )}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color='default'
                    variant='contained'
                    onClick={() =>
                        analyticsStore.closeForm(AnalyticsForms.UpdateMetrics)
                    }
                >
                    Закрыть
                </Button>
                <Button
                    color='primary'
                    variant={'contained'}
                    disabled={analyticsStore.isMetricsLoading}
                    onClick={async () => {
                        await updateMetrics();
                    }}
                >
                    Загрузить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(UpdateMetricsForm);
