import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../hooks';
import { ManualReportDataDto } from '../../../../../services/manualReports/requestResponses';
import Table from 'rsuite/Table';
import { Paper } from '../../../../../components/libs';
import { getWorkTimeStr } from '../../../../../utils';

const ManualReportsTable: React.FC = () => {
    const { manualReportsStore } = useStores();

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const newData = manualReportsStore.manualReports.map((dayData, i) => {
            const vehiclesData: {
                vehicleClass: string;
                data: ManualReportDataDto;
            }[] = [];

            for (const vehicleClass in dayData.dataByClass) {
                const vehicleData = dayData.dataByClass[vehicleClass];

                vehiclesData.push({
                    vehicleClass: vehicleClass,
                    data: vehicleData,
                });
            }

            vehiclesData.sort((a, b) => {
                const aTotal = a.data.work + a.data.idle;
                const bTotal = b.data.work + b.data.idle;

                return bTotal - aTotal;
            });

            return {
                id: `${i}`,
                label: dayData.dayStr,
                work: getWorkTimeStr(dayData.totalData.work),
                idle: getWorkTimeStr(dayData.totalData.idle),
                ins: dayData.totalData.ins,
                outs: dayData.totalData.outs,
                children: vehiclesData.map((vehicleData, j) => {
                    return {
                        id: `${i}-${j}`,
                        label: vehicleData.vehicleClass,
                        work: getWorkTimeStr(vehicleData.data.work),
                        idle: getWorkTimeStr(vehicleData.data.idle),
                        ins: vehicleData.data.ins,
                        outs: vehicleData.data.outs,
                    };
                }),
            };
        });

        setTableData(newData);
    }, [manualReportsStore.manualReports]);

    return (
        <Paper p={2}>
            <Table
                loading={manualReportsStore.isLoading}
                data={tableData}
                defaultExpandAllRows
                bordered
                cellBordered
                rowKey='id'
                // height={400}
                autoHeight
                isTree
            >
                <Table.Column flexGrow={2}>
                    <Table.HeaderCell>День/Вид техники</Table.HeaderCell>
                    <Table.Cell dataKey='label' />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Работа (мин)</Table.HeaderCell>
                    <Table.Cell dataKey={'work'} />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Простой (мин)</Table.HeaderCell>
                    <Table.Cell dataKey='idle' />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Въездов</Table.HeaderCell>
                    <Table.Cell dataKey='ins' />
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Выездов</Table.HeaderCell>
                    <Table.Cell dataKey='outs' />
                </Table.Column>
            </Table>
        </Paper>
    );
};

export default observer(ManualReportsTable);
