import React, { useEffect } from 'react';
import FiltersBlock from './components/FiltersBlock';
import { useStores } from '../../../../hooks';
import PlaceSelect from '../../../../components/PlaceSelect';
import MetricsTable from '../ManualReports/components/MetricsTable';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import UpdateMetricsForm from './forms/UpdateMetricsForm';

const MetricsPage: React.FC = () => {
    const { generalStore } = useStores();

    useEffect(() => {
        generalStore.setPageTitle('Метрики', [<PlaceSelect />]);
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gridGap: 20,
            }}
        >
            <FiltersBlock />
            <MetricsTable />
            <UpdateMetricsForm />
        </Box>
    );
};
export default observer(MetricsPage);
