import { Button, FormControl, Paper } from '../../../../../components/libs';
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useStores } from '../../../../../hooks';
import { ManualReportsForms } from '../../../../../stores/manualReports';
import { DatePicker } from '../../../../../utils/DatePicker';
import { DateRange } from 'rsuite/DateRangePicker';
import moment from 'moment';

const FiltersBlock: React.FC = () => {
    const { manualReportsStore, placesStore } = useStores();

    const [dateRange, setDateRange] = useState<DateRange>([
        moment().subtract(7, 'days').toDate(),
        moment().toDate(),
    ]);

    useEffect(() => {
        console.log('use effect');
        if (dateRange?.[0] && dateRange?.[1] && placesStore.currentPlace) {
            console.log('get reports');
            getManualReports();
        }
    }, [dateRange, placesStore.currentPlace?.id]);

    const getManualReports = () => {
        manualReportsStore.setLoading(true);
        manualReportsStore.getManualReports(placesStore.currentPlace.id, {
            sinceDt: moment(dateRange[0]).hour(12).toDate(),
            tillDt: moment(dateRange[1]).hour(12).toDate(),
        });
        manualReportsStore.setLoading(false);
    };

    return (
        <Paper p={2}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gridGap: 10,
                    }}
                >
                    <DatePicker
                        onChange={(value) => {
                            setDateRange(value);
                        }}
                        value={dateRange}
                        size={'lg'}
                    />
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        disabled={!dateRange?.[0] || !dateRange?.[1]}
                        onClick={() => {
                            getManualReports();
                        }}
                    >
                        Показать
                    </Button>
                </Box>
                <Box
                    sx={{
                        alignContent: 'center',
                    }}
                >
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() =>
                            manualReportsStore.openForm(
                                ManualReportsForms.LoadForm,
                            )
                        }
                    >
                        Загрузить отчеты
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default FiltersBlock;
