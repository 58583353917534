import { RouteInfoType } from '../../types/types';
import LayoutWithMenu from '../../layouts/LayoutWithMenu';
import React from 'react';
import ManualReportsPage from './pages/ManualReports/ManualReportsPage';
import MetricsPage from './pages/Metrics/MetricsPage';

const qualityRoutes = {
    id: 'quality',
    path: '/quality',
    name: 'quality',
    icon: null,
    hide: true,
    roles: ['maintainer'],
    component: <LayoutWithMenu />,
    routes: [
        {
            index: true,
            path: '',
            redirect: '/quality/dashboard',
            // exact: true,
            hide: true,
        },
        {
            path: 'quality',
            header: 'Качество',
            type: 'section',
        },
        {
            path: '/quality/manual-reports',
            name: 'Отчеты для проверки',
            type: 'route',
            component: <ManualReportsPage />,
            icon: null,
        },
        {
            path: '/quality/metrics',
            name: 'Метрики',
            type: 'route',
            component: <MetricsPage />,
            icon: null,
        },
    ],
} as RouteInfoType;

export default qualityRoutes;
