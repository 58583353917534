import { action, makeAutoObservable, runInAction } from 'mobx';
import { request } from '../apiCommandsExecutor';
import {
    DayDataDto,
    GetManualReportsRequest,
    GetManualReportsResponse,
    LoadManualReportsRequest,
    LoadManualReportsResponse,
} from '../services/manualReports/requestResponses';

export enum ManualReportsForms {
    LoadForm = 'LoadForm',
}

class ManualReportsStore {
    formsState: { [key in ManualReportsForms]: boolean } = {
        LoadForm: false,
    };
    isLoading: boolean;
    manualReports: DayDataDto[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setLoading(v: boolean) {
        this.isLoading = v;
    }

    @action
    openForm(type: keyof typeof ManualReportsForms) {
        this.formsState[type] = true;
    }

    @action
    closeForm(type: keyof typeof ManualReportsForms) {
        this.formsState[type] = false;
    }

    @action
    async loadManualReports(placeId: string, params: LoadManualReportsRequest) {
        const result = await request<LoadManualReportsResponse>(
            `/manual_reports/${placeId}/load`,
            {
                method: 'POST',
                data: {
                    ...params,
                },
            },
        );

        if (result) {
            return result.daysResults;
        } else {
            return null;
        }
    }

    @action
    async getManualReports(placeId: string, params: GetManualReportsRequest) {
        const result = await request<GetManualReportsResponse>(
            `/manual_reports/${placeId}`,
            {
                method: 'GET',
                params: params,
            },
        );

        if (result) {
            runInAction(() => {
                this.manualReports = [...result.daysData];
            });
        }
    }
}

export default ManualReportsStore;
